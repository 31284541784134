<template>
  <div class="navtionPage">
    <div class="navtion">
      <div class="logo" @click="toHome">
        Lucky Holdings Pvt. Ltd.
      </div>
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        router
      >
      <!-- 个人贷 -->
        <el-submenu popper-class="personalLoan" index="personalLoan">
          <template slot="title">Personal Loan</template>
          <el-menu-item index="">
            <div class="personalLoanItem">
              <div class="personalLoanImg">
                <img src="@/assets/PersonalLoan/Microfinance.png" alt="">
              </div>
              <div class="personalLoanInfo">
                <h6>Microfinance</h6>
                <p>Personal loan annual interest rate 10%-35%</p>
              </div>
            </div>
            <div class="personalLoanItem">
              <div class="personalLoanImg">
                <img src="@/assets/PersonalLoan/HomeLoan.png" alt="">
              </div>
              <div class="personalLoanInfo">
                <h6>Home Loan</h6>
                <p>Apply online with annual interest rates from 8% to 12%</p>
                <ul>
                  <li>Home Extension Loan</li>
                  <li>Affordable Housing Loan</li>
                  <li>Home Construction Loan</li>
                </ul>
              </div>
            </div>
          </el-menu-item>
          <el-menu-item index="">
            <div class="personalLoanItem">
              <div class="personalLoanImg">
                <img src="@/assets/PersonalLoan/VehicleLoan.png" alt="">
              </div>
              <div class="personalLoanInfo">
                <h6>Vehicle Loan</h6>
                <p>Secure vehicle loans and effortlessly own your dream car</p>
                <ul>
                  <li>Used Car Loan</li>
                  <li>Two Wheeler Loan</li>
                </ul>
              </div>
            </div>
            <div class="personalLoanItem">
              <div class="personalLoanImg">
                <img src="@/assets/PersonalLoan/LoanAgainstSecurities.png" alt="">
              </div>
              <div class="personalLoanInfo">
                <h6>Loan Against Securities</h6>
                <p>Invest without stress and attain financial freedom effortlessly</p>
                <ul>
                  <li>Loan against Shares</li>
                  <li>Loan against mutual funds</li>
                </ul>
              </div>
            </div>
          </el-menu-item>
        </el-submenu>
        <!-- 消费者贷款 -->
        <el-submenu popper-class='consumerLoan' index="consumerLoan">
          <template slot="title">Consumer Loan</template>
          <el-menu-item :route="{name: 'travelLoan'}"  index="">Travel Loan</el-menu-item>
          <el-menu-item :route="{name: 'educationLoan'}" index="">Education Loan</el-menu-item>
          <el-menu-item :route="{name: 'medicalLoan'}" index="">Medical Loan</el-menu-item>
        </el-submenu>
        <el-submenu popper-class='businessLoan' index="businessLoan">
          <template slot="title">Business Loan</template>
          <el-menu-item :route="{name: 'workingCapital'}" index="">Working Capital Demand Loan</el-menu-item>
          <el-menu-item :route="{name: 'securitiesLoan'}" index="">Loan Against Securities</el-menu-item>
          <el-menu-item :route="{name: 'termLoan'}" index="">Term Loan</el-menu-item>
        </el-submenu>
        <el-submenu popper-class='insurance' index="insurance">
          <template slot="title">Insurance</template>
          <el-menu-item :route="{name: 'vehicleInsurance'}" index="">Motor Vehicle Insurance</el-menu-item>
          <el-menu-item :route="{name: 'lifeInsurance'}" index="">Life Insurance</el-menu-item>
          <el-menu-item :route="{name: 'healthInsurance'}" index="">Health Insurance</el-menu-item>
          <el-menu-item :route="{name: 'travelInsurance'}" index="">Travel Insurance</el-menu-item>
          <el-menu-item :route="{name: 'wellnessProgramme'}" index="">Health and Wellness Programme</el-menu-item>
        </el-submenu>
        <el-submenu router popper-class='aboutUs' index="aboutUs">
          <template slot="title">About US</template>
          <el-menu-item :route="{name: 'overview'}" index="overview">Overview</el-menu-item>
          <el-menu-item :route="{name: 'keyPeople'}" index="keyPeople">Key People</el-menu-item>
          <el-menu-item :route="{name: 'alliances'}" index="alliances">Alliances</el-menu-item>
          <el-menu-item :route="{name: 'awards'}" index="awards">Awards</el-menu-item>
          <el-menu-item :route="{name: 'ourServices'}" index="ourServices">Our Services</el-menu-item>
        </el-submenu>
      </el-menu>
      <div class="btn" style="cursor: pointer;">Login</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'navigationIndex',
  data () {
    return {
      activeIndex: '1'
    }
  },
  methods: {
    handleSelect () {

    },
    toHome () {
      if (this.$route.name === 'home') {
        location.reload()
      } else {
        this.$router.replace({ name: 'home' })
      }
    }
  }
}
</script>

<style scoped lang="less">
.navtionPage {
  background-color: #175CA4;
}
.navtion {
  width: 1400px;
  background-color: #175CA4;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
  .logo {
    margin-right: 100px;
    cursor: pointer;
    img {
      width: 260px;
    }
  }
}
.el-menu-demo{
  display: flex;
  color: #fff ;
  cursor: pointer;
  background-color: #175CA4;

  .el-menu-item {
    margin-right: 50px;
    font-size: 14px;
    font-weight: 600;
    color: #fff !important;
  }
  ::v-deep .el-submenu__title {
    color: #fff !important;
    font-weight: 700;
    i {
      color: #fff;
      font-weight: 700;
    }
    &:hover {
      background-color: #175CA4 !important;
    }
  }
  ::v-deep &.el-menu--horizontal {
    border-bottom: none;
  }
}
.btn {
  width: 100px;
  background-color: #FAF800;
  color: #175CA4;
  font-size: 12px;
  font-weight: 700;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
}
.el-menu--horizontal>.el-menu-item:not(.is-disabled):hover {
  background-color: rgba(0, 0, 0, 0) !important;
}
.el-menu--horizontal>.el-menu-item:not(.is-disabled):focus {
  background-color: rgba(0, 0, 0, 0) !important;
}
::v-deep .el-submenu.is-active .el-submenu__title {
  color: #FAF800 !important;
  border-bottom: 1px solid  #175CA4 !important;
}
// 个人贷
.personalLoan {
  .el-menu-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto !important;
    padding: 17px 34px;
    .personalLoanItem {
      min-height: 120px;
      padding: 17px 24px;
      display: flex;
      margin: 10px;
      &:hover {
        background-color: rgb(240,248,255);
      }
      .personalLoanImg {
        width: 41px;
        height: 41px;
        margin-right: 10px;
        img {
          width: 100%;
        }
      }
      .personalLoanInfo {
        line-height: normal;
        white-space:normal;
        width: 260px;
        h6 {
          font-size: 14px;
          color: #1F1F1E;
        }
        p {
          font-size: 12px;
          color: #656961;
          margin: 8px 0;
        }
        ul {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          li {
            width: 50%;
            color: #1F1F1E;
            font-size: 9px;
            &::before {
              content: "●"; /* 使用content属性创建自定义列表标记 */
              color: #175CA4;
              font-size: 6px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}
// 消费者贷款
.consumerLoan,
.businessLoan,
.insurance,
.aboutUs{
  .el-menu-item{
      color:#1F1F1E !important;
      font-weight: 600;
      margin: 15px;
    &:hover {
      color: #175CA4 !important;
    }
  }
}
</style>
